import {encrypt} from '@/utils'

/**
 * Custom HTTP headers
 * @param classroomId
 * @param studentId
 */
export const headers = (
  classroomId: string = '',
  studentId: string = ''
): {} => {
  return {
    'X-Resource-ID': `${encrypt(classroomId)}`,
    'X-Student-ID': `${encrypt(studentId)}`,
  }
}
