import {useEffect, useState} from 'react'
import {useTheme} from 'styled-components'
import {Modal, Container, Text, Button} from 'cc-web-components'
import {useTranslation} from 'react-i18next'
import isBefore from 'date-fns/isBefore'

import Images from '@/assets/images'
import useDeviceDetection from '@/hooks/IsMobile'
import {LinkButton} from './styles'

const startingDate = new Date(2025, 0, 13)

export const ModalAppExit: React.FC = () => {
  const {colors} = useTheme()
  const {t} = useTranslation('cornelsen')
  const {isMobile} = useDeviceDetection()
  const [isVisible, setIsVisible] = useState(false)
  const isTodayBeforeStartingDate = isBefore(new Date(), startingDate)
  const isAppExitModalShown = localStorage.getItem('appExitModalShown') === 'true'

  useEffect(() => {
    if (!isAppExitModalShown && !isTodayBeforeStartingDate) {
      setIsVisible(true)
      localStorage.setItem('appExitModalShown', 'true')
    }
  }, [isTodayBeforeStartingDate, isAppExitModalShown])

  const renderControls = () => (
    <Container
      mt={32}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="center"
      alignItems="center"
      style={{gap: '8px'}}>
      <LinkButton
        target="_blank"
        href="https://www.cornelsen.de/digital/chatclass"
        rel="noreferrer">
        {t('Home.AppExitModal.MoreInfo')}
      </LinkButton>
      <Button
        type="button"
        variant="solid"
        value={t('Home.AppExitModal.Close')}
        fontSize={18}
        fontWeight={400}
        height={48}
        width={isMobile ? '300px' : '244px'}
        paddingRight={20}
        paddingLeft={20}
        style={{
          boxShadow: 'none',
        }}
        onClick={() => {
          setIsVisible(false)
        }}
      />
    </Container>
  )

  return (
    <Container id="modalWrapper">
      <Modal
        show={isVisible}
        width="720px"
        backgroundColor={colors.white}
        padding={isMobile ? '0 30px 30px' : '32px 48px'}>
        <Container flexDirection="column" alignItems="center">
          <Container justifyContent="center" alignItems="center" marginBottom="24px">
            <img src={Images.AdaGreeting} alt="" width={267 * (216 / 283)} height={216} />
          </Container>
          <Container flexDirection="column" alignItems="center" width={'480px'}>
            <Text
              lineHeight={'32px'}
              weight={600}
              fontSize={28}
              color="#263238"
              mb={2}
              value={t('Home.AppExitModal.Title')}
            />
            <Text
              fontSize={20}
              fontWeight={400}
              lineHeight={'24px'}
              color="#263238"
              textAlign="center"
              value={t('Home.AppExitModal.Subtitle')}
            />
          </Container>
        </Container>
        {renderControls()}
      </Modal>
    </Container>
  )
}
