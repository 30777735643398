import {Text} from 'cc-web-components'
import {Container} from './styles'
import {isMobile} from 'react-device-detect'
import {useTranslation} from 'react-i18next'

export const TopBanner = () => {
  const {t} = useTranslation('cornelsen')
  return (
    <Container href="https://www.cornelsen.de/digital/chatclass" target="_blank" rel="noreferrer">
      <Text
        value={t('Home.AppExitBanner.Info')}
        fontSize={isMobile ? 18 : 24}
        fontWeight={600}
        color={'#945900'}
        lineHeight="28px"
      />
      <p>&nbsp;</p>
      <Text
        value={t('Home.AppExitBanner.Link')}
        fontSize={isMobile ? 18 : 24}
        fontWeight={600}
        color={'#945900'}
        lineHeight="28px"
        style={{textDecoration: 'underline'}}
      />
    </Container>
  )
}
