import styled from 'styled-components'

export const Container = styled.a`
  width: 100%;
  height: 68px;
  background-color: #ffecc3;
  position: fixed;
  top: 0;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`
