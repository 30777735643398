import styled from 'styled-components'

export const Modal = styled.div`
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #fff;
`

export const LinkButton = styled.a`
  font-size: 18px;
  color: #1a73e8;
  text-decoration: none;
  border: 1px solid #ddd;
  border-radius: 24px;
  height: 48px;
  width: 244px;
  display: flex;
  justify-content: center;
  align-items: center;
`
