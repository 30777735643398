import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js/core';

const KEY = 'gEqvoo8JPH53sjU1'
const IV = '0jXG6LwqtqJoCljz'

/**
 * The encryption method
 * @param value string
 */
export const encrypt = (value: string): string => {
  if (value && value.trim().length) {
    return AES.encrypt(value, CryptoJS.enc.Utf8.parse(KEY), {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      iv: CryptoJS.enc.Utf8.parse(IV),
    }).toString()
  }
  return value
}
