import styled, {css} from 'styled-components';

import {ChatMessageFromKeys} from './types';

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  background-color: #f0ece7;
  padding: 12px;
  min-height: 100px;
  box-sizing: border-box;
  border-left: 20px solid #fff;
  border-right: 20px solid #fff;
  flex-direction: column;
  overflow-x: auto;
`;

export const MessageNumber = styled.div<{color?: string}>`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  align-self: flex-start;
  margin-right: 5px;
  margin-top: 10px;
  background-color: ${props => props.color || 'transparent'};
`;

export const MessageContainer = styled.div<{type: ChatMessageFromKeys}>`
  display: flex;

  ${props =>
    props.type === 'BOT' &&
    css`
      justify-content: flex-start;
      justify-content: flex-start;
    `}

  ${props =>
    props.type === 'USER' &&
    css`
      align-items: flex-end;
      justify-content: flex-end;
    `}

  ${props =>
    props.type === 'FEEDBACK' &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export const MessageItem = styled.div<{type: ChatMessageFromKeys}>`
  max-width: 340px;
  text-align: left;
  padding: 10px;
  margin-top: 10px;
  border-radius: 12px;
  font-size: 14px;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: left;

  ${props =>
    props.type === 'BOT' &&
    css`
      background-color: #fff;
      color: #000;
    `}

  ${props =>
    props.type === 'USER' &&
    css`
      background-color: #6091e7;
      color: #000;
    `}

  ${props =>
    props.type === 'FEEDBACK' &&
    css`
      background-color: #e8f1fc;
      color: #000;
    `}
`;

export const MessageImage = styled.img`
  width: 100%;
  border-radius: 8px;
`;
