import {getShouldAppExitBannerBeShown} from '@/redux/Session'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

const useIsTopBannerShown = () => {
  const location = useLocation()
  const shouldAppExitBannerBeShown = useSelector(getShouldAppExitBannerBeShown)
  const showTopBanner = shouldAppExitBannerBeShown && location.pathname === '/'

  return showTopBanner
}

export default useIsTopBannerShown
