import {createAction, createReducer, createSelector} from '@reduxjs/toolkit'

import {Alert, License, SessionState, User} from './types'
import {Routes} from '@cc/shared'
import isBefore from 'date-fns/isBefore'

const {NEW_BOOKS} = Routes
const startingDate = new Date(2025, 0, 13)

/** Initial state */
export const INITIAL_STATE: SessionState = {
  user: undefined,
  license: undefined,
  isLoading: false,
  alert: null,
  channel: 'cornselsen',
  isShowModalPricingTable: false,
  isShowUserProfile: false,
  avvConfirmed: {value: false},
  shouldAppExitBannerBeShown: !isBefore(new Date(), startingDate),
}

/** Action creators */
export const loginSuccess = createAction<{user?: User}>('LOGIN_SUCCESS')
export const showAlert = createAction<Alert>('SHOW_ALERT')
export const hiderAlert = createAction('HIDE_ALERT')
export const startLoading = createAction('START_LOADING')
export const stopLoading = createAction('STOP_LOADING')
export const logout = createAction('LOGOUT')
export const setChannel = createAction<{channel: null | string}>('SET_CHANNEL')
export const setLicense = createAction<{license: License}>('SET_LICENSE')
export const toggleShowModalPricingTable = createAction<{visible: boolean}>('SHOW_MODAL_')
export const toggleShowUserProfile = createAction<{visible: boolean}>('SHOW_USER_PROFILE')
export const setIsAvvConfirmed = createAction<{value: boolean}>('SET_IS_AVV_CONFIRMED')
/** Reducer */
export default createReducer(INITIAL_STATE, {
  [showAlert.type]: (state, action): SessionState => ({
    ...state,
    alert: action.payload,
  }),
  [hiderAlert.type]: (state): SessionState => ({
    ...state,
    alert: null,
  }),
  [loginSuccess.type]: (state, action): SessionState => ({
    ...state,
    ...action.payload,
  }),
  [startLoading.type]: (state, action): SessionState => ({
    ...state,
    isLoading: true,
  }),
  [stopLoading.type]: (state): SessionState => ({
    ...state,
    isLoading: false,
  }),
  [logout.type]: (): SessionState => ({
    ...INITIAL_STATE,
  }),
  [setChannel.type]: (state, action): SessionState => ({
    ...state,
    ...action.payload,
  }),
  [setLicense.type]: (state, action): SessionState => ({
    ...state,
    ...action.payload,
  }),
  [toggleShowModalPricingTable.type]: (state, action): SessionState => ({
    ...state,
    isShowModalPricingTable: action.payload.visible,
  }),
  [toggleShowUserProfile.type]: (state, action): SessionState => ({
    ...state,
    isShowUserProfile: action.payload.visible,
  }),
  [setIsAvvConfirmed.type]: (state, action): SessionState => ({
    ...state,
    avvConfirmed: action.payload,
  }),
})

/** Selectors */
const rootSelector = (state: any): SessionState => state.session

export const getUser = createSelector([rootSelector], state => state.user)

export const getProfile = createSelector([rootSelector], state => state.user?.profile)

export const getMenu = createSelector([rootSelector], state => {
  if (!state.user?.profile) {
    return []
  }

  const menuItemsAdmin = [
    {label: 'dashboard', route: '/dashboard'},
    {label: 'users', route: '/users-list'},
    {label: 'activities', route: '/teacher-activities'},
    {label: 'classes', route: '/student-details/1'},
    {label: 'reports', route: '/reports'},
  ]

  const menuItemsTeacher = [{label: 'activities', route: '/teacher-activities'}]

  const menuItemsCornelsen = [
    {label: 'MyClasses', route: '/'},
    {label: 'Books', route: NEW_BOOKS},
  ]

  return {
    'no-profile': [],
    admin: menuItemsAdmin,
    teacher: menuItemsTeacher,
    'teacher-cv': menuItemsCornelsen,
    'teacher-bsp': menuItemsCornelsen,
  }[state.user?.profile]
})

export const getIsHideFeaturesUser = createSelector([rootSelector], state => {
  return state.channel !== null && state.channel !== 'cornelsen'
})

export const getLoading = createSelector([rootSelector], state => state.isLoading)

export const getAlertValue = createSelector([rootSelector], state => state.alert)

export const getLicense = createSelector([rootSelector], state => state.license)
export const getShouldAppExitBannerBeShown = createSelector(
  [rootSelector],
  state => state.shouldAppExitBannerBeShown,
)

export const getIsShowModalPricingTable = createSelector(
  [rootSelector],
  state => state.isShowModalPricingTable,
)

export const getIsShowUserProfile = createSelector([rootSelector], state => state.isShowUserProfile)

export const getIsAvvConfirmed = createSelector([rootSelector], state => state.avvConfirmed)
