import React, {CSSProperties, ReactNode, useState} from 'react'
import {
  MenuRight,
  StyledMenu,
  StyledBrand,
  StyledLogo,
  StyledTitle,
  StyledHeader,
  StyledMenuItem,
  StyledExit,
  StyledMenuItems,
  StyledMobileMenuIcon,
  MenuRightMobile,
} from './styles'
import {BsChevronRight} from 'react-icons/bs'

export type MenuItem = {
  readonly icon?: any
  readonly label: string
  readonly route: string
}

export type Props = {
  readonly title?: string
  readonly logoUrl: string | ReactNode
  readonly items: MenuItem[]
  readonly activeIndex?: number
  readonly exitContent?: ReactNode
  readonly menuRightContent?: ReactNode
  readonly menuRightStyle?: CSSProperties
  readonly openMenu?: boolean
  readonly onPressLogo?: () => void
  readonly onPressExit?: () => void
  readonly onHoverItem?: (item: MenuItem, index: number) => void
  readonly onPressItem: (item: MenuItem, index: number) => void
  readonly renderItem: (item: MenuItem, index: number) => any
  topBannerShown?: boolean
}

export const Menu: React.FC<Props> = ({
  items,
  title,
  logoUrl,
  exitContent,
  menuRightContent,
  menuRightStyle,
  activeIndex,
  openMenu,
  renderItem,
  onHoverItem,
  onPressItem,
  onPressLogo,
  onPressExit,
  topBannerShown,
}: Props) => {
  const [isOpen, setOpen] = useState(!!openMenu || false)

  const pressItem = (item: MenuItem, index: number) => {
    setOpen(false)

    onPressItem(item, index)
  }

  const pressLogo = () => {
    setOpen(false)

    if (onPressLogo) {
      onPressLogo()
    }
  }

  const RenderMenu = () => (
    <>
      <StyledMenu open={isOpen}>
        <StyledMenuItems>
          {items.map((item, index) => (
            <StyledMenuItem
              key={index}
              isActive={index === activeIndex}
              onClick={() => pressItem(item, index)}
              onMouseEnter={() => !!onHoverItem && onHoverItem(item, index)}>
              {renderItem(item, index)}
              <BsChevronRight size={32} color={'blue'} />
            </StyledMenuItem>
          ))}
        </StyledMenuItems>

        <MenuRight style={menuRightStyle}>
          {menuRightContent}

          {exitContent && onPressExit && (
            <StyledExit onClick={onPressExit}>{exitContent}</StyledExit>
          )}
        </MenuRight>
      </StyledMenu>

      <MenuRightMobile style={menuRightStyle}>
        {menuRightContent}

        {exitContent && onPressExit && <StyledExit onClick={onPressExit}>{exitContent}</StyledExit>}
      </MenuRightMobile>
    </>
  )

  const RenderBrand = () => (
    <StyledBrand onClick={pressLogo}>
      {typeof logoUrl === 'string' ? <StyledLogo src={logoUrl} /> : logoUrl}
      <StyledTitle>{title}</StyledTitle>
    </StyledBrand>
  )

  const RenderMobileMenuIcon = () => (
    <StyledMobileMenuIcon open={isOpen} onClick={() => setOpen(!isOpen)}>
      <span />
      <span />
      <span />
      <label>Menu</label>
    </StyledMobileMenuIcon>
  )

  return (
    <StyledHeader className="menu-header" topBannerShown={topBannerShown}>
      <RenderBrand />

      <RenderMobileMenuIcon />

      <RenderMenu />
    </StyledHeader>
  )
}
