import Service from './apiConfig'
import qs from 'qs'

import { Student, Classroom, ClassroomAllResponse, ClassroomLicense } from './types/Classroom'
import { ActivityResponse, ActivityType } from './types/Activity'
import { Compentence, CompetenceResponse } from './types/Competence'
import {AxiosRequestConfig} from 'axios'
import {encrypt} from '@/utils'
import {headers} from '@/conf'

export type ClassroomServiceRequest = {
  classroomId?: string
  filter?: {}
  payload?: any
}

type UpdateClassroomRequest = {
  classroomIds?: string[]
  payload?: any
}

const ApiClassroomService = {
  getAll: async (): Promise<Classroom[]> => {
    const response = (await Service.get<ClassroomAllResponse>('/api/classroom/all')).data

    return response.classrooms
  },
  getAllWithLicense: async (): Promise<ClassroomLicense[]> => {
    const response = (await Service.get('/api/classroom/all')).data

    return response.classrooms
  },
  getStudentsById: async (classroomId: string): Promise<Student[]> => {
    const response = (await Service.get<Student[]>(`/api/classroom/students`,
      {
        headers: headers(classroomId),
      }) as AxiosRequestConfig).data;

    response.forEach((student: any) => {
      student.student.selected = false
      student.student.name = student.student.name === "Jakub Nowak" ? "Name gelöscht" : student.student.name
    })

    return response
  },
  getCompetences: async (params: ClassroomServiceRequest): Promise<CompetenceResponse> => {
    const { classroomId, filter } = params

    const content = (
      await Service.get<Compentence>(`/api/classroom/competencies`, {
        headers: headers(classroomId),
        params: { classroomId, ...filter },
      })
    ).data

    return { content }
  },
  getActivitiesByType: async (
    type: ActivityType,
    params: ClassroomServiceRequest
  ): Promise<ActivityResponse> => {
    const { filter, classroomId } = params

    const { content, correctness, total_elements, pageable, total_pages } = (
      await Service.get<ActivityResponse>(`/api/classroom/stats`, {
        headers: headers(classroomId),
        params: { type, ...filter },
      })
    ).data

    const contentParsed = content.map((activity) => {
      const number_items = activity.items.length
      const number_items_correct = activity.items.filter((item) => item.correct).length

      activity.correct = number_items_correct === number_items
      activity.number_items = number_items
      activity.has_feedback = !!activity.feedbacks.length
      activity.number_items_correct = number_items_correct

      return activity
    })

    return {
      content: contentParsed,
      correctness,
      total_elements,
      pageable,
      total_pages,
    }
  },
  getActivitiesVocab: async (params: ClassroomServiceRequest) => {
    return ApiClassroomService.getActivitiesByType(ActivityType.VOCABULARY_TRAINER, params)
  },
  getActivitiesSpeechTasks: async (params: ClassroomServiceRequest) => {
    return ApiClassroomService.getActivitiesByType(ActivityType.AI_SPEAKING, params)
  },
  createClassroom: async (params: ClassroomServiceRequest) => {
    const { payload } = params

    return (await Service.post(`/api/classroom`, payload)).data
  },
  updateClassroom: async (params: ClassroomServiceRequest) => {
    const { classroomId, payload } = params

    return (await Service.put(`/api/classroom`, payload, {
      headers: headers(classroomId)
    })).data
  },
  updateMultipleClassrooms: async (params: UpdateClassroomRequest) => {
    const { payload, classroomIds } = params

    return (
      await Service.put(`/api/classroom/update`, payload, {
        params: { id: classroomIds },
        paramsSerializer: (params) => qs.stringify(params, { indices: false }),
      })
    ).data
  },
  deleteClassroom: async (classroomId: string) => {
    return (await Service.delete<void>(`/api/classroom`, {
      headers: headers(classroomId)
    })).data
  },
  convertData: async (data: any) => {
    const { content, correctness, total_elements, pageable, total_pages } = data

    const contentParsed = content.map((activity: any) => {
      const number_items = activity.items.length
      const number_items_correct = activity.items.filter((item: any) => item.correct).length

      activity.correct = number_items_correct === number_items
      activity.number_items = number_items
      activity.has_feedback = !!activity.feedbacks.length
      activity.number_items_correct = number_items_correct
      activity.user_name = activity.user_name === "Jakub Nowak" ? "Name gelöscht" : activity.user_name

      return activity
    })

    return {
      content: contentParsed,
      correctness,
      total_elements,
      pageable,
      total_pages,
    }
  },
  getActivitiesVocabByQuery: async (params: any) => {
    const { filter, classroomId } = params

    const data = (
      await Service.get<void>(
        `/api/classroom/stats?type=LISTENING&type=DIALOG&type=GRAMMAR&type=VOCABULARY&type=VOCABULARY_TRAINER`,
        { headers: headers(classroomId), params: { ...filter } }
      )
    ).data

    return ApiClassroomService.convertData(data)
  },
  getActivitiesSpeakByQuery: async (params: any) => {
    const { filter, classroomId } = params

    const data = (
      await Service.get<void>(
        `/api/classroom/stats?type=SPEAKING&type=AI_SPEAKING&type=PRONUNCIATION&type=PICK_A_PIC`,
        { headers: headers(classroomId), params: { ...filter } }
      )
    ).data

    return ApiClassroomService.convertData(data)
  },

  getActivities: async (params: any) => {
    const { filter, classroomId, types } = params

    const data = (
      await Service.get<void>(
        `/api/classroom/stats${types}`,
        { headers: headers(classroomId), params: { ...filter } }
      )
    ).data

    return ApiClassroomService.convertData(data)
  },
}

export default ApiClassroomService
