import Service from './apiConfig'

import { ActivityResponse, ActivityType, FeedbackMessage } from './types/Activity'
import { Compentence, CompetenceResponse } from './types/Competence'
import {headers} from '@/conf'

export type StudentCompetenceRequest = {
  studentId: string
  classroomId: string
  filter: {}
}

export type StudentServiceRequest = {
  studentId: string
  classroomId: string
  filter: {}
}

export type StudentFeedbackTextRequest = {
  studentId: string
  classroomId: string
  payload: {
    statId: string
    feedback: string
  }
}

export type StudentFeedbackMediaRequest = {
  studentId: string
  classroomId: string
  payload: FormData
}

export type StudentFeedbackTextResponse = {
  id: string
  classroom_id: string
  user_id: string
  activity_id: string
  details: {
    feedback: FeedbackMessage
  }
}

const ApiStudentService = {
  postAddFeedbackText: async (params: StudentFeedbackTextRequest) => {
    const { classroomId, studentId, payload } = params

    return (
      await Service.post<StudentFeedbackTextResponse>(
        `/api/classroom/student/feedback`,
        payload, {
          headers: headers(classroomId, studentId),
        }
      )
    ).data
  },
  postAddFeedbackMedia: async (params: StudentFeedbackMediaRequest) => {
    const { classroomId, studentId, payload } = params

    return (
      await Service.post<StudentFeedbackTextResponse>(
        `/api/classroom/student/feedbackMedia`,
        payload, {
          headers: headers(classroomId, studentId),
        }
      )
    ).data
  },
  getCompetences: async (params: StudentCompetenceRequest): Promise<CompetenceResponse> => {
    const { classroomId, studentId, filter } = params

    const content = (
      await Service.get<Compentence>(`/api/student/${studentId}/competencies`, {
        params: { classroomId, ...filter },
      })
    ).data

    const graph_values = [
      { name: 'Sprechen', value: content.speaking_total_question },
      { name: 'Aussprache', value: content.pronunciation_total_question },
      { name: 'Vokabeln', value: content.vocab_total_question },
      { name: 'Grammatik', value: content.grammar_total_question },
      { name: 'Hören', value: content.listening_total_question },
      { name: 'Lesen', value: content.reading_total_question },
    ]

    return { content: { ...content, graph_values } }
  },
  getActivitiesByType: async (
    type: ActivityType,
    params: StudentServiceRequest
  ): Promise<ActivityResponse> => {
    const { filter, studentId, classroomId } = params

    const { content, correctness, total_elements } = (
      await Service.get<ActivityResponse>(`/api/student/${studentId}/stats`, {
        params: { classroomId, type, ...filter },
      })
    ).data

    const contentParsed = content.map((activity) => {
      const number_items = activity.items.length
      const number_items_correct = activity.items.filter((item) => item.correct).length

      activity.correct = number_items_correct === number_items
      activity.number_items = number_items
      activity.has_feedback = !!activity.feedbacks.length
      activity.number_items_correct = number_items_correct

      return activity
    })

    return {
      content: contentParsed,
      correctness,
      total_elements,
    }
  },
  getActivitiesVocab: async (params: StudentServiceRequest): Promise<ActivityResponse> => {
    return ApiStudentService.getActivitiesByType(ActivityType.VOCABULARY_TRAINER, params)
  },
  getActivitiesSpeechTasks: async (params: StudentServiceRequest): Promise<ActivityResponse> => {
    return ApiStudentService.getActivitiesByType(ActivityType.AI_SPEAKING, params)
  },
  getSurveys: async () => {
    return (await Service.get(`/api/survey/stats`)).data
  },
  convertData: (data: any) => {
    const { content, correctness, total_elements, pageable, total_pages } = data

    const contentParsed = content.map((activity: any) => {
      const number_items = activity.items.length
      const number_items_correct = activity.items.filter((item: any) => item.correct).length

      activity.correct = number_items_correct === number_items
      activity.number_items = number_items
      activity.has_feedback = !!activity.feedbacks.length
      activity.number_items_correct = number_items_correct

      return activity
    })

    return {
      content: contentParsed,
      correctness,
      total_elements,
      pageable,
      total_pages,
    }
  },
  getActivitiesVocabByQuery: async (params: any) => {
    const { filter, studentId, classroomId } = params

    const data = (
      await Service.get<void>(
        `/api/student/${studentId}/stats?type=LISTENING&type=DIALOG&type=GRAMMAR&type=VOCABULARY&type=VOCABULARY_TRAINER`,
        { params: { classroomId, ...filter } }
      )
    ).data

    return ApiStudentService.convertData(data)
  },
  getActivitiesSpeakByQuery: async (params: any) => {
    const { filter, studentId, classroomId } = params

    const data = (
      await Service.get<void>(
        `/api/student/${studentId}/stats?type=SPEAKING&type=AI_SPEAKING&type=PRONUNCIATION&type=PICK_A_PIC`,
        { params: { classroomId, ...filter } }
      )
    ).data

    return ApiStudentService.convertData(data)
  },
  getActivities: async (params: any) => {
    const { filter, studentId, classroomId, types } = params

    const data = (
      await Service.get<void>(`/api/student/${studentId}/stats${types}`, {
        params: { classroomId, ...filter },
      })
    ).data

    return ApiStudentService.convertData(data)
  },
}

export default ApiStudentService
