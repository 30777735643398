import React from 'react';
import {useTheme} from 'styled-components';
import {Container, Text} from 'cc-web-components';

import AudioPlayer from '@/components/AudioPlayer';

import Xp from './Xp';
import {
  Chat as IChat,
  ChatMessage as IChatMessage,
  ChatMessageTypeKeys as EChatMessageTypeKeys,
  ChatMessageFromKeys as EChatMessageFromKeys,
  ChatMessageFromKeys,
} from './types';
import {Wrapper, MessageNumber, MessageItem, MessageContainer, MessageImage} from './styles';

type Props = {
  chat: IChat[];
  xp_number: number;
};

const MessageList: React.FC<Props> = (props: Props) => {
  const {colors} = useTheme();

  const RenderUser = (message: IChatMessage) => {
    if (message.from === EChatMessageFromKeys.BOT || !message?.metadata?.user_name) return <></>;

    const {user_name, user_emoji} = message.metadata;

    return (
      <Text
        fontWeight={600}
        color={message.from === EChatMessageFromKeys.USER ? '#fff' : '#000'}
        value={`${user_emoji || ''}  ${user_name === 'Jakub Nowak' ? 'Name gelöscht' : user_name}`}
      />
    );
  };

  const RenderOptionsMessage = (message: IChatMessage) => {
    if (!message.options?.length) return <></>;

    return (
      <Container marginTop={1} style={{gap: '4px'}} flexWrap="wrap" flexDirection="row">
        {message.options.map(option => {
          if (option.correct !== undefined) {
            return (
              <Container
                key={option.text}
                padding="5px"
                borderRadius={2}
                borderStyle="solid"
                borderWidth="1px"
                borderColor={option.correct ? '#4ade80' : '#f87171'}>
                <Text value={option.text} />
              </Container>
            );
          }

          return (
            <Container key={option.text} padding="5px" borderRadius={2} backgroundColor="#e5e5e5">
              <Text value={option.text} />
            </Container>
          );
        })}
      </Container>
    );
  };

  const RenderContentMessage = (message: IChatMessage) => {
    if (message.from !== ChatMessageFromKeys.BOT && message.type === EChatMessageTypeKeys.HTML) {
      return <p>{message.content}</p>;
    }
    switch (message.type) {
      case EChatMessageTypeKeys.HTML: {
        return <span dangerouslySetInnerHTML={{__html: message.content}}></span>;
      }
      case EChatMessageTypeKeys.IMAGE: {
        return <MessageImage src={message.content} alt="" />;
      }
      case EChatMessageTypeKeys.AUDIO: {
        return <AudioPlayer src={message.content} />;
      }
    }
  };

  const RenderMessage = (message: IChatMessage) => {
    return (
      <Container flexDirection="column">
        {RenderUser(message)}

        {RenderContentMessage(message)}

        {RenderOptionsMessage(message)}
      </Container>
    );
  };

  return (
    <Wrapper>
      <Container flexDirection="column" maxWidth="600px" margin="auto">
        <Container width={'100%'} margin="auto" justifyContent="flex-end">
          <Xp value={props.xp_number} />
        </Container>

        {props.chat.map(chat => (
          <Container key={chat.index} flex={1} flexDirection="row">
            {typeof chat.index !== 'undefined' && (
              <MessageNumber color={chat.colorIndex}>
                <Text color={colors.gray[1]} value={chat.index + 1} />
              </MessageNumber>
            )}

            <Container flexDirection="column" flex={1}>
              {chat.messages.map((message, index) => (
                <MessageContainer key={index} type={message.from}>
                  <MessageItem type={message.from}>{RenderMessage(message)}</MessageItem>
                </MessageContainer>
              ))}

              {chat.correct_text && (
                <MessageContainer type={EChatMessageFromKeys.BOT}>
                  <MessageItem
                    type={EChatMessageFromKeys.BOT}
                    style={{border: '1px solid #4ade80'}}>
                    {chat.correct_text}
                  </MessageItem>
                </MessageContainer>
              )}
            </Container>
          </Container>
        ))}
      </Container>
    </Wrapper>
  );
};

export default MessageList;
