import {useEffect, useState} from 'react'
import {Button, Container, Text} from 'cc-web-components'
import {useDispatch} from 'react-redux'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'

import Images from '@/assets/images'
import {useAssignActivityContext} from '@/redux/AssignActivity'
import {showAlert, toggleShowUserProfile} from '@/redux/Session'
import ApiLicenseService, {LicenseAllType} from '@/services/ApiCv/ApiLicenseService'
import {
  CircleCrossEmptyLinedIcon,
  CircleGreenCheckLinedIcon,
  CircleRedCheckLinedIcon,
  ClassLinedGrayIcon,
  ClassLinedIcon,
} from '@/components/Icons'

import {LicenseOption, IconWrapper} from './styles'
import {openWindow} from '@/utils'
import {ExternalLinkSvg} from '@/assets'

const License = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation('cornelsen')
  const {colors} = useTheme()
  const [licenses, setLicenses] = useState<LicenseAllType[]>([])
  const [, {toggleVisibleForm, toggleIsActivateClass, updateLicenseIdToActiveClass}] =
    useAssignActivityContext()

  useEffect(() => {
    ApiLicenseService.getAllLicenses()
      .then(data => {
        setLicenses(data)
      })
      .catch(() => {
        dispatch(
          showAlert({
            value: 'Something went wrong',
            variant: 'danger',
          }),
        )
      })
  }, [dispatch])

  const getClassName = (license: LicenseAllType) => {
    const activeClass = license.classrooms.find(classroom => !classroom.code.includes('deleted'))

    return activeClass.display_name
  }

  const handleActivateClass = (licenseId: string) => {
    updateLicenseIdToActiveClass(licenseId)
    toggleIsActivateClass()
    dispatch(toggleShowUserProfile({visible: false}))
    toggleVisibleForm()
  }

  const renderLicenseInfo = (license: LicenseAllType, index: number) => {
    const date = license.active_until.slice(0, 10).slice(0, 10).split('-').reverse().join('.')
    const isInactive =
      license.classrooms.every((classroom: any) => classroom.code.includes('deleted')) ||
      !license.classrooms.length
    const isClassLicense = license.type === 'CCPPK' || license.type === 'CCWAL'

    return (
      <LicenseOption key={index}>
        {isInactive && <CircleCrossEmptyLinedIcon width={30} />}

        {license.expired && <CircleRedCheckLinedIcon width={30} />}

        {!license.expired && !isInactive && <CircleGreenCheckLinedIcon width={30} />}

        <Container flexDirection="column" ml={3}>
          {isClassLicense && !isInactive ? (
            <Container justifyContent="space-between">
              <Text value={t('UserProfile.ClassLicense')} color={colors.black} fontSize={20} />

              <Container width={120} ml={2}>
                {license.expired ? (
                  <ClassLinedGrayIcon width={15} />
                ) : (
                  <ClassLinedIcon width={15} />
                )}

                <Text
                  value={getClassName(license)}
                  color={license.expired ? colors.gray[2] : colors.primary}
                  fontSize={14}
                  ml={1}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: 2,
                  }}
                />
              </Container>
            </Container>
          ) : (
            !isInactive && (
              <Text value={t('UserProfile.SchoolLicense')} color={colors.black} fontSize={20} />
            )
          )}

          {isInactive && (
            <Container justifyContent="space-between" alignItems="center">
              <Text
                value={
                  isClassLicense ? t('UserProfile.ClassLicense') : t('UserProfile.SchoolLicense')
                }
                color={colors.gray[2]}
                fontSize={20}
                mr={2}
              />

              <Button
                type="button"
                variant="solid-full"
                value="aktivieren"
                onClick={() => handleActivateClass(license.licence_id)}
                height={30}
                fontSize={16}
                fontWeight={400}
                px={3}
                style={{
                  backgroundColor: colors.white,
                  border: '1px solid #ddd',
                  color: colors.primary,
                  boxShadow: 'none',
                }}
              />
            </Container>
          )}

          <Text
            fontSize={16}
            value={license.expired ? `abgelaufen seit ${date}` : `gültig bis ${date}`}
            color={colors.gray[2]}
          />
        </Container>
      </LicenseOption>
    )
  }

  const renderTipText = () => (
    <>
      {licenses.length > 3 ? (
        <Text
          color={colors.gray[2]}
          value={t('UserProfile.License.Tip.SchoolLicense')}
          fontSize={16}
          textAlign="center"
          marginTop={16}
          fontWeight={400}
        />
      ) : (
        <Text
          color={colors.gray[2]}
          value={t('UserProfile.License.Tip.ClassLicense')}
          fontSize={16}
          textAlign="center"
          marginTop={16}
          fontWeight={400}
        />
      )}
    </>
  )

  return (
    <>
      <Container
        flexDirection="column"
        justifyContent="space-between"
        overflowY="auto"
        height="calc(100vh - 213px)">
        <Container
          width="100%"
          flexDirection="column"
          justifyContent="center"
          backgroundColor={colors.white}>
          <Text
            value={t('UserProfile.License.Title')}
            fontSize={24}
            color={colors.black}
            textAlign="center"
            mx={3}
            my={5}
          />

          {licenses.length !== 0 && (
            <Container flexDirection="column">
              {licenses.map((license, index) => renderLicenseInfo(license, index))}
            </Container>
          )}
        </Container>

        <Container flexDirection="column" alignItems="center" p={3}>
          <img src={Images.IdeaBlue} alt="" width={45} height={45} />

          {licenses && renderTipText()}
        </Container>
      </Container>

      <Container p={4} justifyContent="center" backgroundColor={colors.white}>
        <Button
          type="button"
          variant="link"
          value={t('UserProfile.License.Actions.Buy')}
          onClick={() => openWindow('https://www.cornelsen.de/digital/chatclass')}
          height={40}
          fontSize={16}
          fontWeight={400}
          leftContent={
            <Container mr={2}>
              <IconWrapper>
                <ExternalLinkSvg fill="#FFFFFF" />
              </IconWrapper>
            </Container>
          }
          px={3}
          style={{
            backgroundColor: colors.primary,
            border: '1px solid #ddd',
            color: colors.white,
            boxShadow: 'none',
            borderRadius: '24px',
          }}
        />
      </Container>
    </>
  )
}

export default License
