import Service from './apiConfig'
import ServiceFeedback from './apiFeedbackConfig'
import {headers} from '@/conf'

export type LicenseType = {
  active_until: string
  licence_id: string
  expired: boolean
  days_to_expire: number
  type: 'CCWAL' | 'CCWAS' | 'CCWAE' | 'CCPPK' | 'CCPPS' | 'TESTLICENSE01'
  test: boolean
}

export type LicenseAllType = {
  active_until: string
  licence_id: string
  expired: boolean
  days_to_expire: number
  type: 'CCWAL' | 'CCWAS' | 'CCWAE' | 'CCPPK' | 'CCPPS' | 'TESTLICENSE01'
  test: boolean
  classrooms: any[]
}

type FeedbackLicenseParamsProps = {
  userId: string | undefined
  licenseId: string | undefined
}

type addFeedbackLicenseParamsProps = {
  payload: {
    customer: string
    refusal_reason: string
    refusal_reason_other: string
    accept_share: boolean
    improvements_message: string
    custom_data: {
      user_id: string | undefined
      license_id: string | undefined
    }
  }
}

const ApiLicenseService = {
  getAvailableLicense: async (): Promise<LicenseType[]> => {
    return (await Service.get(`/api/license/available`)).data
  },
  getAllLicenses: async (): Promise<LicenseAllType[]> => {
    return (await Service.get(`/api/license/all`)).data
  },
  getFeedbackLicense: async (params: FeedbackLicenseParamsProps) => {
    const { userId, licenseId } = params

    return (
      await ServiceFeedback.get(
        `/api/v1/feedback?custom_search_value=${userId}&custom_search_value=${licenseId}&custom_search_name=user_id&custom_search_name=license_id`
      )
    ).data
  },
  postAddFeedbackLicense: async (params: addFeedbackLicenseParamsProps) => {
    const { payload } = params

    return (await ServiceFeedback.post(`/api/v1/feedback`, payload)).data
  },
  postReactiveLicense: async (params: any) => {
    const { classroomId, licenseId } = params

    return await Service.post(`/api/classroom/licence/${licenseId}/reactivate`, null, {
      headers: headers(classroomId),
    })
  }
}

export default ApiLicenseService
