import styled from 'styled-components'

export const LicenseOption = styled.div`
  display: flex;
  padding: 20px 16px;
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid #eee;

  &:first-child {
    border-top: 1px solid #eee;
  }
`

export const IconWrapper = styled.span`
  svg path {
    fill: #ffffff;
  }
`
