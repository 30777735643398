import styled, {css} from 'styled-components'

export const StyledHeader = styled.div<{topBannerShown?: boolean}>`
  top: ${prop => (prop.topBannerShown ? '68px' : '0')};
  right: 0;
  left: 0;
  height: 60px;
  padding: 0px 20px;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  z-index: ${props => props.theme.zIndex.Menu};
  background: ${props => props.theme.colors.white};
  box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    justify-content: end;
  }
`

export const StyledMobileMenuIcon = styled.button<{open: boolean}>`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  transition: all 300ms;
  background: transparent;
  outline: none;
  cursor: pointer;
  margin-left: 45px;
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  label {
    position: absolute;
    right: -45px;
    font-size: 16px;
    top: 5px;
    color: ${props => props.theme.colors.primary};
  }

  span {
    height: 2px;
    width: 25px;
    background: ${props => props.theme.colors.primary};
    margin: 3px;
    transition: all 300ms;

    ${props =>
      props.open &&
      css`
        &:nth-of-type(1) {
          transform: rotate(45deg);
          margin: 0px !important;
          margin-bottom: -1px !important;
        }
        &:nth-of-type(2) {
          display: none;
        }
        &:nth-of-type(3) {
          transform: rotate(-45deg);
          margin: 0px !important;
          margin-top: -1px !important;
        }
      `}
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export const StyledMenu = styled.div<{open: boolean}>`
  position: absolute;
  top: 60px;
  left: 0;
  height: 0px;
  width: 1350px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: ${props => props.theme.zIndex.Menu};
  ${props => props.open && 'height: calc(100vh - 60px);'}
  background: ${props => props.theme.colors.white};

  @media (max-width: 768px) {
    ${props => (props.open ? 'display: flex;' : 'display: none;')}
  }

  @media (min-width: 768px) {
    position: relative;
    top: 0;
    height: 100%;
    flex-direction: row;
  }

  @media (min-width: 1120px) {
    padding-right: 0;
  }
`

export const StyledMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  justify-content: flex-start;
  flex: 1;

  @media (min-width: 768px) {
    flex-direction: row;
    padding-left: 45px;
    justify-content: flex-end;
  }
`

export const StyledMenuItem = styled.div<{isActive?: boolean}>`
  padding: 15px 20px;
  font-size: 20px;
  cursor: pointer;
  transition: all 300ms;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: ${props => (props.isActive ? props.theme.colors.primary : props.theme.colors.black)};

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (min-width: 768px) {
    svg {
      display: none;
    }
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => (props.isActive ? props.theme.colors.primary : props.theme.colors.black)};

    ${props =>
      props.isActive &&
      css`
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 20px;
          width: calc(100% - 40px);
          height: 4px;
          background: ${props.theme.colors.primary};
        }
      `}

    &:hover {
      background-color: transparent;
      color: ${props => props.theme.colors.primary};
    }
  }
`

export const MenuRight = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const MenuRightMobile = styled.div`
  height: 60px;
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 20px;

  @media (max-width: 768px) {
    display: flex;
  }
`

export const StyledExit = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  width: auto;
  height: 60px;
  background: ${props => props.theme.colors.white};

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  @media (min-width: 768px) {
    margin: 0;
  }
`

export const StyledBrand = styled.div`
  z-index: ${props => props.theme.zIndex.Modal};
  position: absolute;
  top: 14px;
  left: 20px;
  display: flex;
  cursor: pointer;
`

export const StyledLogo = styled.img`
  width: 32px;
  height: 32px;
`

export const StyledTitle = styled.label`
  display: none;
  margin-left: 20px;
  font-size: 20px;
  line-height: 32px;
  color: ${props => props.theme.colors.black};

  @media (min-width: 1024px) {
    display: inline;
  }
`
