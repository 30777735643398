import {useEffect, useCallback, useRef, useState} from 'react'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {Button, Container, Text} from 'cc-web-components'
import {useDispatch, useSelector} from 'react-redux'

import useDeviceDetection from '@/hooks/IsMobile'
import ApiLicenseService, {LicenseAllType} from '@/services/ApiCv/ApiLicenseService'
import {getIsShowUserProfile, getUser, toggleShowUserProfile} from '@/redux/Session'

import {ChevronLinedIcon, CloseBlueIcon, LogoutWhiteIcon} from '../Icons'

import {
  Wrapper,
  Content,
  UserIcon,
  TrialDaysBar,
  RemainingDaysBar,
  TopNavigationWrapper,
} from './styles'
import FeedbackOption from './Feedback'
import LicenseOption from './License'
import {useLogout} from '@/hooks'
import {NavigationOptions} from './components'
import useIsTopBannerShown from '@/hooks/useIsTopBannerShown'

const LICENSE_BAR_MIN_DAYS_TO_SHOW = 30

export const OptionTypes = {
  USER_PROFILE: 0,
  FEEDBACK: 1,
  LICENSE: 2,
}

const UserProfile = () => {
  const dispatch = useDispatch()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const {t} = useTranslation('cornelsen')
  const {isMobile} = useDeviceDetection()
  const {colors} = useTheme()
  const isShowUserProfile = useSelector(getIsShowUserProfile)
  const user = useSelector(getUser)
  const {navigateToLogout} = useLogout()
  const isTopBannerShown = useIsTopBannerShown()

  const [option, setOption] = useState(OptionTypes.USER_PROFILE)
  const [licenses, setLicenses] = useState<LicenseAllType[]>([])
  const [topNavigationText, setTopNavigationText] = useState('')

  const handleClickWrapper = useCallback(
    (event: any) => {
      if (!wrapperRef || !wrapperRef.current) return

      if (wrapperRef.current.contains(event.target)) return

      dispatch(toggleShowUserProfile({visible: false}))
    },
    [dispatch],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickWrapper)

    return () => {
      document.removeEventListener('mousedown', handleClickWrapper)
    }
  }, [handleClickWrapper])

  useEffect(() => {
    if (isShowUserProfile) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isShowUserProfile])

  useEffect(() => {
    ApiLicenseService.getAllLicenses().then(licenses => {
      setLicenses(licenses.filter(item => !item.expired))
    })
  }, [])

  const getUserFormatted = () => {
    return user?.name?.substr(0, 2).toUpperCase()
  }

  const getClassName = (license: LicenseAllType) => {
    const activeClass = license.classrooms.find(classroom => !classroom.code.includes('deleted'))

    return activeClass.display_name
  }

  const renderLicenseToExpire = (license: LicenseAllType, index: number) => {
    const isClassLicense = license.type === 'CCPPK' || license.type === 'CCWAL'
    const isInactive =
      license.classrooms.every((classroom: any) => classroom.code.includes('deleted')) ||
      !license.classrooms.length

    return (
      license.days_to_expire <= 30 && (
        <Container key={index} flexDirection="column" p="20px" style={{gap: '8px'}}>
          <Container justifyContent="space-between" flexWrap="wrap">
            {!isInactive && isClassLicense ? (
              <Text
                value={getClassName(license)}
                fontSize={18}
                fontWeight={700}
                color={colors.black}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '180px',
                }}
              />
            ) : (
              <Text
                value={
                  isClassLicense ? t('UserProfile.ClassLicense') : t('UserProfile.SchoolLicense')
                }
                fontSize={18}
                fontWeight={700}
                color={colors.black}
              />
            )}
            <Text value={`${license.days_to_expire} Tage`} fontSize={18} color={colors.gray[1]} />
          </Container>
          <TrialDaysBar>
            <RemainingDaysBar
              totalDays={LICENSE_BAR_MIN_DAYS_TO_SHOW}
              leftDays={license.days_to_expire}
            />
          </TrialDaysBar>
        </Container>
      )
    )
  }

  const handleOnFeedbackClick = () => {
    setTopNavigationText(t('UserProfile.Feedback.Header'))
    setOption(OptionTypes.FEEDBACK)
  }

  const handleOnLicenseClick = () => {
    setTopNavigationText(t('UserProfile.License.Header'))
    setOption(OptionTypes.LICENSE)
  }

  const renderUserProfile = () => (
    <>
      <Container
        flexDirection="column"
        justifyContent="space-between"
        overflowY="auto"
        height={isTopBannerShown ? 'calc(100vh - 232px)' : 'calc(100vh - 164px)'}>
        <Container flexDirection="column">
          {!!user && (
            <Container flexDirection="column" justifyContent="center" alignItems="center" py={4}>
              <UserIcon>
                <Text
                  value={getUserFormatted()}
                  fontSize={24}
                  fontWeight={700}
                  color={colors.white}
                />
              </UserIcon>

              <Text value={user.name} fontSize={18} color={colors.black} mt={2} />
            </Container>
          )}

          <Container flexDirection="column">
            <NavigationOptions
              onFeedbackClick={handleOnFeedbackClick}
              onLicenseClick={handleOnLicenseClick}
            />
          </Container>
        </Container>

        {licenses.map((license, index) => renderLicenseToExpire(license, index))}
      </Container>
      <Container justifyContent="center" backgroundColor={colors.white} p={4}>
        <Button
          type="button"
          variant="solid"
          leftContent={
            <Container mr={2}>
              <LogoutWhiteIcon width={20} />
            </Container>
          }
          value={t('UserProfile.Actions.Exit')}
          fontSize={16}
          fontWeight={400}
          height={40}
          onClick={navigateToLogout}
          px={3}
        />
      </Container>
    </>
  )

  if (!isShowUserProfile) {
    return <></>
  }

  return (
    <Wrapper>
      <Content ref={wrapperRef} isMobile={isMobile}>
        <Container flexDirection="column">
          {option !== OptionTypes.USER_PROFILE && (
            <TopNavigationWrapper>
              <Container alignItems="center">
                <Button
                  variant="link"
                  onClick={() => setOption(OptionTypes.USER_PROFILE)}
                  leftContent={<ChevronLinedIcon />}
                />
                <Text
                  value={topNavigationText}
                  fontSize={18}
                  fontWeight={700}
                  color={colors.black}
                />
              </Container>
              <Button
                variant="link"
                onClick={() => dispatch(toggleShowUserProfile({visible: false}))}
                leftContent={<CloseBlueIcon />}
              />
            </TopNavigationWrapper>
          )}
          {option === OptionTypes.USER_PROFILE && renderUserProfile()}
          {option === OptionTypes.LICENSE && <LicenseOption />}
          {option === OptionTypes.FEEDBACK && (
            <FeedbackOption closeRequest={() => setOption(OptionTypes.USER_PROFILE)} />
          )}
          {option === OptionTypes.FEEDBACK && (
            <FeedbackOption closeRequest={() => setOption(OptionTypes.USER_PROFILE)} />
          )}
        </Container>
      </Content>
    </Wrapper>
  )
}

export default UserProfile
